.app{
  position: relative;
}
.back-to-top{
  position: absolute;
  right: 20px;
  bottom: 100px !important;
  font-size: 50px !important;
  color: var(--primary-2);
  cursor: pointer;
}
.footer-section{
  position: absolute;
  bottom: 0;
  width: 100%;
}