.home-main{
    margin-bottom: 50vh !important;
}
.home-title{
    text-align: center;
}
.home-group{
 display: flex !important;   
 justify-content: center !important;
 align-items: center !important;
 gap: 20px !important;
}
.home-item{
    padding: 20px !important;
    width: fit-content !important;
    height: auto !important;
    border: thin solid var(--style-gray) !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    text-align: center !important;
    cursor: pointer;
}
@media screen and (max-width:550px) {
    .home-group{
        flex-wrap: wrap !important;
    }
    .home-item{
        width: 90% !important;
        margin: auto !important;
    }
}
