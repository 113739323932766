.navbar-link{
    color: var(--primary-1) !important;
    margin: 10px;
    text-decoration: none;
}
.nav-link{
    color: rgb(31, 29, 29) !important;
}
.logo{
    width: 50px;
}
/* Header */
.header-main{
    background: #4b6cb7; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4b6cb7, #182848); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4b6cb7, #182848); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: fit-content;
}
.header{
padding: 120px 20px 20px 20px;
background-color:var(--primary-1);
background-image: url(./footer-img.jpg);
background-repeat: no-repeat;
background-size: cover;
}
