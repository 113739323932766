.profile-container{
    margin: 100px auto !important;
    width: 100% !important;
    margin: auto;
}
.profile-img-wrapper{
    width: 150px !important;
    height: 150px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.profile-img{
    width: 150px !important;
    height: 150px;
    border-radius: 50% !important;

}
.img-overlay{
    width: 150px !important;
    height: 150px !important;
    position: absolute;
    top: 0px;
    z-index: 1 !important;
    background: transparent;
    cursor: pointer;
}
.img-overlay::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .img-overlay::before {
    content: 'Change Image';
    color: white;
  }
.selection{
    padding: 10px;
}
.informational{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content !important;
    height: 250px !important;
    gap: 10px;
    margin-top: 30px !important;
}
.out-link{
    margin:20px;
}
.payment-btn-wrapper{
    display: block;
    margin-bottom: 20px;
}
.profile-btn{
    /* margin-top: 50px !important; */
    width: fit-content !important;
    background-color: var(--primary-2);
    color: var(--primary-1);
    border-radius: 4px;
    border: thin solid var(--primary-1);
    font-size: small;
    padding: 5px 20px;
}