/* Filename: styles.css */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300');

:root{
  --family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* --primary-1:#007bff; */
  /* --primary-1:purple; */
  /* --primary-4:rgb(254, 0, 254); */
  /* --primary-1:#1359a4; */
  /* --primary-3:rgb(28, 3, 28); */

  --primary-1:#05386f;
  /* rgb(17, 76, 164) */
  --primary-2:#ffff;
  --primary-3:rgb(21, 3, 67);
  --primary-4:rgb(254, 195, 0);
  --link-color:rgb(255, 196, 0);
  --primary-5:rgb(19, 1, 19);
  --danger-1:rgb(192, 58, 58);
  --danger-2:#ffff;
  --danger-3:rgb(209, 174, 174);
  --style-skye-blue:rgb(229, 237, 240);
  --transition:ease-in 1s;
  --input-bg:rgb(233, 240, 240);
  /* --style-blue-dark: rgb(3, 25, 71);
  --style-blue-footer:hsl(210,25%,8%); */
  --gold:gold;
  --style-blue-dark: rgb(200, 34, 73);
  --style-blue-footer:hsl(341, 99%, 48%);
  --style-white: #fff;
  --style-bright: #f5f6fa;
  --light-bg:rgb(242, 234, 234);
  --style-dark: #1e2532;
  --style-black: #000;
  --style-gray:gray;
  --white-shadow:rgb(238, 229, 229);
}
body {
    /* background-color: rgb(219, 226, 227) !important; */
    /* background-color: rgb(219, 224, 227) !important; */
    font-family: 'Comfortaa', sans-serif !important;
    margin: 0;
    /* background-image: linear-gradient(to right, var(--primary-5), var(--primary-1), black) !important; */
    color: var(--style-gray) !important;
    /* background-color: var(--primary-1) !important;
    color: var(--primary-2) !important; */
}

.top-pane {
  background-color: hsl(225, 6%, 25%);
}
.auth-form{
  padding: 15px;
  border-left: 1px solid var(--style-gray);
  border-right: 5px solid var(--primary-4);
  border-bottom: 2px solid var(--primary-4);
  border-radius: 30px;
}
.button{
  padding: 5px 15px;
  width: fit-content;
  color: var(--link-color);
  background-color: var(--primary-4) !important;
  border-radius: 3px;
  border: thin solid var(--primary-4);
  margin-top: 20px;
}
.link-button{
  padding: 5px 15px;
  width: fit-content;
  color: var(--link-color);
  background-color: var(--primary-2) !important;
  border-radius: 3px;
  border: thin solid var(--primary-4) !important;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;
}
.auth-button{
    padding: 5px 15px;
    width: fit-content;
    font-weight: bold;
    color: var(--primary-2);
    background-color: var(--primary-4) !important;
    border-radius: 3px;
    border: thin solid var(--primary-2) !important;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;
}
.link-button-card{
  padding: 5px 15px;
  width: fit-content;
  color: var(--link-color);
  background-color: var(--primary-2) !important;
  border-radius: 3px;
  border: thin solid var(--primary-4) !important;
  margin: 20px auto;
  cursor: pointer;
  text-decoration: none;
}
.link{
  margin: 10px;
}
.button-danger{
  padding: 5px 15px;
  color: var(--danger-1);
  background-color: var(--danger-2) !important;
  border-radius: 3px;
  border: thin solid var(--danger-1);
  margin-top: 20px;
}
.pane {
  height: 50vh;
  display: flex;
}
.CodeMirror {
  height: 100% !important;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  overflow: hidden;
}